import React, { useState, useEffect, lazy, Suspense } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { createGlobalStyle } from 'styled-components';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { PrimaryButton } from '@rexlabs/button';
import { withQuery, query } from '@rexlabs/model-generator';
import formModel from 'data/models/entities/forms';
import { COLORS } from 'src/theme';
import {
  transformFieldsApiResponseToBuilder,
  transformFieldsBuilderToApiResponse
} from 'utils/forms';
import { useToast } from 'view/components/toast';
import { RenderLoading } from 'view/components/render-loading';
import { Checkbox } from '@rexlabs/checkbox';

const FormBuilder = lazy(() =>
  import(/* webpackChunkName: "form-builder" */ 'view/components/form-builder')
);

const GlobalStyle = createGlobalStyle`
  .drag-copy.btn-primary.formcomponent,
  .drag-copy.btn-primary.formcomponent:hover {
    background: ${COLORS.SAND.DARK};
    border-width: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .formio-dialog.formio-dialog-theme-default {
    padding-top: 4rem;
    padding-bottom: 4rem;

    & > .formio-dialog-content {
      border-radius: 0;
      background: ${COLORS.WHITE};
      font-family: Lato, "OpenSans", sans-serif;
    }
  }

  .formio-dialog-content div.row:first-child > div.col:last-child {
    display: none;
  }

  .formio-dialog-content .lead {
    font-weight: 800;
  }

  .card .card-header h4 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 2.4rem;
  }

  .nav-tabs {
    border-width: 0;
    margin-bottom: 2.4rem;

    & > li > a {
      &,
      &.active,
      &:hover,
      &.active:hover,
      &:focus,
      &.active:focus {
        border-width: 0;
        border-bottom-width: 3px;
        border-color: transparent;
        color: ${COLORS.PRIMARY_DARK};
      }

      &:hover {
        background: none;
        border-color: ${COLORS.BACKGROUND};
      }

      &[href="#api"],
      &[href="#logic"],
      &[href="#layout"] {
        display: none;
      }
    }

    & > li > a.active {
      &,
      &:hover,
      &:focus {
        border-color: ${COLORS.PRIMARY_DARK};
      }
    }
  }

  .formio-component-labelPosition,
  .formio-component-widget.type,
  .formio-component-tabindex,
  .formio-component-mask,
  .formio-component-autofocus,
  .formio-component-spellcheck,
  .formio-component-disabled,
  .formio-component-alwaysEnabled,
  .formio-component-tableView,
  .formio-component-persistent,
  .formio-component-protected,
  .formio-component-dbIndex,
  .formio-component-encrypted,
  .formio-component-redrawOn,
  .formio-component-clearOnHide,
  .formio-component-customDefaultValuePanel,
  .formio-component-calculateValuePanel,
  .formio-component-allowCalculateOverride,
  .formio-component-dataType,
  .formio-component-template,
  .formio-component-searchEnabled,
  .formio-component-selectThreshold,
  .formio-component-customOptions,
  .formio-component-editor,
  .formio-component-case,
  .formio-component-inputFormat,
  .formio-component-storage,
  .formio-component-dir,
  .formio-component-fileNameTemplate,
  .formio-component-image,
  .formio-component-webcam,
  .formio-component-region,
  .formio-component-key, {
    display: none;
  }

  .fileSelector + .alert {
    display: none;
  }
`;

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    padding: '2.4rem',
    maxWidth: '100%',

    '& > div, & > div > div, & .formbuilder, & .formcomponents': {
      height: '100%'
    },

    '& .formio': {
      display: 'flex',
      flex: 1,
      margin: 0,
      padding: 0
    },

    '& .formcomponents': {
      width: '20rem',
      margin: 0,
      padding: 0
    },

    '& .formarea': {
      display: 'flex',
      flex: 1,
      width: '80rem',
      margin: 0,
      padding: '2.4rem',

      '& .formio-component': {
        width: '100%'
      },

      '& .btn': {
        margin: '0 0 .1rem',
        border: '0 none',
        borderRadius: 0,
        padding: '1rem',
        background: COLORS.SAND.DARK,
        justifyContent: 'flex-start'
      },

      '& .btn:hover': {
        background: COLORS.PRIMARY_DARK
      },

      '& .btn.drag-copy': {
        padding: '2rem',
        marginBottom: '1.5rem'
      },

      '& .component-settings-button': {
        background: COLORS.BACKGROUND,
        padding: 0,
        boxShadow: 'none',
        margin: '0 0 0 .1rem',
        width: '2.2rem',
        height: '2.2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '0 none',

        '&:hover': {
          background: COLORS.SAND
        }
      },

      '& .component-settings-button-remove': {
        '&, &:hover': {
          background: COLORS.RED
        }
      }
    },

    '& .builder-sidebar': {
      background: COLORS.SAND,
      minHeight: '100%',

      '& .collapse': {
        display: 'block !important'
      },

      '& .btn, & .btn:hover, & .btn.btn-primary,  & .btn.btn-primary:hover': {
        margin: '0 0 .1rem',
        border: '0 none',
        borderRadius: 0,
        padding: '.6rem 1rem',
        background: COLORS.SAND.DARK,
        justifyContent: 'flex-start',

        '&.builder-group-button': {
          padding: '.6rem 1rem .1rem'
        },

        ['&[data-type="tags"], ' +
        '&[data-type="datetime"], ' +
        '&[data-type="currency"], ' +
        '&[data-type="survey"], ' +
        '&[data-type="signature"], ' +
        // '&[data-type="htmlelement"], ' +
        '&[data-type="content"], ' +
        '&[data-type="panel"], ' +
        '&[data-type="table"], ' +
        '&[data-type="tabs"], ' +
        '&[data-type="well"], ' +
        '&[data-type="container"], ' +
        '&[data-type="datamap"], ' +
        '&[data-type="datagrid"], ' +
        '&[data-type="editgrid"], ' +
        '&[data-type="tree"], ' +
        '&[data-type="resource"], ' +
        '&[data-type="columns"], ' +
        '&[data-type="form"]']: {
          display: 'none'
        }
      },

      '.card.form-builder-panel[ref=group-panel-advanced], .card.form-builder-panel[ref=group-panel-data], .card.form-builder-panel[ref=group-panel-premium], #group-container-basic .formcomponent[data-key=button], #group-container-basic .formcomponent[data-key=password]': {
        display: 'none'
      },

      '& .btn:hover': {
        background: COLORS.PRIMARY_DARK
      },

      '& .builder-group-button': {
        fontSize: '1.2rem',
        fontWeight: 900,

        '&, &:hover': {
          background: 'transparent'
        }
      }
    },

    '& .drag-and-drop-alert': {
      border: '0 none',
      background: COLORS.BACKGROUND,
      color: COLORS.SAND,
      fontWeight: 900,
      padding: '3rem',
      marginBottom: '1.5rem'
    }
  },

  submit: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    width: '18rem'
  }
});

const q = query`{
  ${formModel} (websiteId: ${(props) => props.match.params.websiteId}, id: ${(
  props
) => props.match.params.formId}) {
    id
    definition
    created_at
  }
}`;

let formBuilderValue = { display: 'form' };

function FormEditScreen({ styles: s, forms, match }) {
  const formId = match.params?.formId;
  const [isLoading, setIsLoading] = useState(!!formId);
  const { addToast } = useToast();

  const handleSubmit = async (values) => {
    const fields = transformFieldsBuilderToApiResponse({
      form: formBuilderValue
    });

    try {
      const form = {
        definition: {
          fields,
          name: values?.formName,
          label: values?.formLabel ?? values?.formName,
          description: values?.formDescription,
          enable_recaptcha: values?.enableReCaptcha
        },
        id: forms?.item?.data?.id
      };

      if (!match?.params?.formId) {
        try {
          await forms.createItem({
            data: form,
            args: {
              websiteId: match?.params?.websiteId
            }
          });
        } catch (e) {
          return <p>An error occurred. Try again later.</p>;
        }
      } else {
        // update existing form
        await forms.updateItem({
          id: match.params.formId,
          data: form
        });
      }

      addToast?.({
        type: 'success',
        title: 'Saved',
        content: 'Form successfully saved.'
      });
      // this.props.onClose();
    } catch (e) {
      console.error(e);
      addToast?.({
        type: 'error',
        title: 'Error',
        content:
          e?.message ?? 'An unknown error occurred while updating preview'
      });
    }
  };

  const initialValues = () => {
    if (!forms.item.data?.definition) return;

    const formData = forms.item.data?.definition;
    formBuilderValue = transformFieldsApiResponseToBuilder(forms?.item?.data);

    return {
      id: formData?.id,
      formName: formData?.name,
      formLabel: formData?.label,
      formDescription: formData?.description,
      enableReCaptcha: formData?.enable_recaptcha
    };
  };

  useEffect(() => {
    if (!isLoading) return;
    if (forms.item.status === 'loaded') {
      setIsLoading(false);
    }
  }, [forms.item.status]);

  useEffect(() => {
    return () => (formBuilderValue = { display: 'form' });
  }, []);

  return (
    <Box flex={1}>
      <GlobalStyle />
      <Box flexDirection='column' flex={1} {...s('container')}>
        <RenderLoading isLoading={isLoading}>
          <ReactForms
            initialValues={formId ? initialValues() : {}}
            handleSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Box
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Field
                    label='Form Name'
                    name='formName'
                    optional={false}
                    Input={TextInput}
                    HelpTooltipContent={() =>
                      'This value should uniquely identify this form - it is used when embedding forms and identifying submissions, but is never displayed on the website'
                    }
                  />
                  <PrimaryButton isLoading={isSubmitting} onClick={submitForm}>
                    Save
                  </PrimaryButton>
                </Box>
                <Field
                  label='Form Label / Title'
                  name='formLabel'
                  optional={true}
                  Input={TextInput}
                  HelpTooltipContent={() =>
                    'This value will be displayed as the title for the form when embedded on a page'
                  }
                />
                <Field
                  label='Form Description'
                  name='formDescription'
                  optional={true}
                  Input={TextInput}
                  HelpTooltipContent={() =>
                    'This optional value will be displayed underneath the form title for the form when embedded on a page'
                  }
                />
                <Field
                  name='enableReCaptcha'
                  optional={false}
                  Input={Checkbox}
                  inputProps={{
                    label: 'Enable reCaptcha'
                  }}
                  HelpTooltipContent={() =>
                    'This option only applies if `Automatically add reCaptcha to all forms` not checked in Form Settings'
                  }
                />
                {/* <Field
                  name='form'
                  optional={false}
                  Input={FormBuilderInput}
                  inputProps={{ setFieldValue }}
                /> */}
                <Suspense fallback={<p>Loading...</p>}>
                  <FormBuilder
                    form={formBuilderValue}
                    onChange={(schema) => (formBuilderValue = schema)}
                  />
                </Suspense>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    </Box>
  );
}

export default compose(withQuery(q), styled(defaultStyles))(FormEditScreen);
