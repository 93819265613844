import { InputProps } from 'view/components/theme-settings/theme-setting-input';
import { Dropdown } from '@rexlabs/select';

const options = [
  { value: '100', label: '100% screen height' },
  { value: '75', label: '75% screen height' },
  { value: '50', label: '50% screen height' }
];

export function getBannerHeightOptionsInput(): InputProps {
  return {
    input: Dropdown,
    inputProps: {
      items: options
    }
  };
}
